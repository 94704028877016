@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap');
 :root {
	 --color-primary: #6b7a8f;
	 --color-secondary: #101118;
	 --color-accent: #1d1f2f;
	 --color-focus: #6d64f7;
	 --base-duration: 600ms;
	 --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}
 *, *:before, *:after {
	 box-sizing: border-box;
}
/*  html, body {
	 height: 100%;
}
 body {
	 font-family: 'IBM Plex Sans', sans-serif;
	 background-color: var(--color-secondary);
} */
 #outerDiv {
	 align-items: center;
	 display: flex;
	 min-height: 100%;
	 justify-content: center;
	 overflow-x: hidden;
	 width: 100%;
}
/*  h1, h2, h3 {
	 font-family: 'Playfair Display', serif;
} */
 .visuallyhidden {
	 clip: rect(1px, 1px, 1px, 1px);
	 height: 1px;
	 overflow: hidden;
	 position: absolute !important;
	 white-space: nowrap;
	 width: 1px;
}
/*  .icon {
	 fill: var(--color-primary);
	 width: 100%;
}
 .btn {
	 background-color: var(--color-primary);
	 border: none;
	 border-radius: 0.125rem;
	 color: white;
	 cursor: pointer;
	 font-family: inherit;
	 font-size: inherit;
	 padding: 1rem 2.5rem 1.125rem;
}
 .btn:focus {
	 outline-color: var(--color-focus);
	 outline-offset: 2px;
	 outline-style: solid;
	 outline-width: 3px;
}
 .btn:active {
	 transform: translateY(1px);
} */
 .slider__controls {
	 display: flex;
	 justify-content: center;
	 position: absolute;
	 top: calc(100% + 1rem);
	 width: 100%;
}
 .slider__controls .btn {
	 --size: 3rem;
	 align-items: center;
	 background-color: transparent;
	 border: 3px solid transparent;
	 border-radius: 100%;
	 display: flex;
	 height: var(--size);
	 padding: 0;
	 width: var(--size);
}
 .slider__controls .btn:focus {
	 border-color: var(--color-focus);
	 outline: none;
}
 .slider__controls .btn--previous > * {
	 transform: rotate(180deg);
}
 .slider {
	 --slide-size: 60vmin;
	 --slide-margin: 4vmin;
	 /* height: var(--slide-size); */
	 margin: 0 auto;
	 position: relative;
	 /* width: var(--slide-size); */
    
     
}
 .slider__wrapper {
	 display: flex;
	 margin: 0 calc(var(--slide-margin) * -1);
	 position: absolute;
	 transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);

 }
 .slide {
	 /* align-items: center; */
	 color: white;
	 /* display: flex; */
	 /* flex: 1; */
	 /* flex-direction: column; */
	/*  height: var(--slide-size); */
	 /* justify-content: center; */
    
	 margin: 0 var(--slide-margin);
	 opacity: 0.50;
	 position: relative;
	 text-align: center;
	 transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
	 /* width: var(--slide-size); */
	 z-index: 1;
     padding-bottom: 5em;
     /* min-height: 450px; */
     /* width: var(--slide-size);  */
}



 .slide--previous:hover, .slide--next:hover {
	 opacity: 0.5;
}
 .slide--previous {
	 cursor: w-resize;
}
 .slide--previous:hover {
	 transform: translateX(2%);
}
 .slide--next {
	 cursor: e-resize;
}
 .slide--next:hover {
	 transform: translateX(-2%);
}
 .slide--current {
	 --x: 0;
	 --y: 0;
	 --d: 50;
	 opacity: 1;
	 pointer-events: auto;
	 user-select: auto;
}
 @media (hover: hover) {
	 .slide--current:hover .slide__image-wrapper {
		 transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
	}
}
 .slide__image-wrapper {
	 background-color: var(--color-accent);
	 border-radius: 1%;
	 height: 100%;
	 left: 0%;
	 overflow: hidden;
	 position: absolute;
	 top: 0%;
	 transition: transform calc(var(--base-duration) / 4) var(--base-ease);
	 width: 100%;
}
 .slide__image {
	 --d: 20;
	 height: 110%;
	 left: -5%;
	 object-fit: cover;
	 opacity: 0;
	 pointer-events: none;
	 position: absolute;
	 top: -5%;
	 transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
	 user-select: none;
	 width: 110%;
}
 @media (hover: hover) {
	 .slide--current .slide__image {
		 transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
	}
}
 .slide__headline {
	 font-size: 8vmin;
	 font-weight: 600;
	 position: relative;
}
 .slide__content {
	 --d: 60;
	 opacity: 0;
	 padding: 4vmin;
	 position: relative;
	 transition: transform var(--base-duration) var(--base-ease);
	 visibility: hidden;
}
 .slide--current .slide__content {
	 animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
	 visibility: visible;
}
 @media (hover: hover) {
	 .slide--current .slide__content {
		 transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
	}
}
 .slide__content > * + * {
	 margin-top: 2rem;
}
 @keyframes fade-in {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
 